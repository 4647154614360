import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component:  () => import('../views/Radio.vue')
  },
  {
    path: '/radio',
    name: 'radio',
    component: () => import('../views/Radio.vue')
  }, 
  {
    path: '/checkbox',
    name: 'checkbox',
    component: () => import('../views/Checkbox.vue')
  }, 
  {
    path: '/judge',
    name: 'judge',
    component: () => import('../views/Judge.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
